import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import AmbitiInterventoTemplate from '../../components/template-ambiti-di-intervento';

const AmbitiInterventoPage = ({ data }) => {
  const { page } = data;
  const { title, tagTitle, metaDescription } = page;
  return (
    <Layout className="home" data={data}>
      <Seo title={tagTitle || title} description={metaDescription} data={data} />
      <AmbitiInterventoTemplate {...page} />
    </Layout>
  );
};

export default AmbitiInterventoPage;
export const query = graphql`
  query AmbitiInterventoPageQuery {
    ...HeaderFragment
    ...SeoFragment
    page: ambitiInterventoPageJson {
      title
      tagTitle
      metaDescription
      intro
      titleDisturbi
      disturbi {
        title
        abstract
        slug
        image {
          childImageSharp {
            gatsbyImageData(height: 270, layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
    }
  }
`;
